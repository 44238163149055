import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Header from "../components/header";
import { Container, ContentContainer } from "../components/layoutComponents";
import ContactForm from "../components/ContactForm";
import BuyManualButton from "../components/header/BuyManualButton";
import SEO from "../components/seo";
import { keywords } from "../constants/Keywords";

const Manual = ({ location }) => {
  return (
    <>
      <SEO title="The Manual" keywords={keywords} />
      <Header path={location.pathname} />
      <Container>
        <ContentContainer padding="8rem 0">
          <h1 style={{ textAlign: "center" }}>The Dome Construction Manual</h1>
          <StaticQuery
            query={graphql`
              query {
                placeholderImage: file(
                  relativePath: { eq: "dome_manual.jpg" }
                ) {
                  childImageSharp {
                    fluid(maxWidth: 400, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            `}
            render={(data) => (
              <Img
                style={{ marginBottom: "2rem" }}
                fluid={data.placeholderImage.childImageSharp.fluid}
              />
            )}
          />
          <h2 style={{ textAlign: "center" }}>
            45 years of experience distilled into a clean, easy-to-reason-about
            format
          </h2>

          <div
            style={{ width: "100%", textAlign: "center", marginBottom: "1rem" }}
          >
            <BuyManualButton />
          </div>

          <p>
            The dome manual brings together 45 years of dome construction to an
            easy to understand format for those with little or no knowledge of
            geometry or trigonometry. It describes the construction of a
            dodecahedron geodesic dome that can be built by a random individual
            with only basic skills in carpentry.
          </p>

          <p>
            The manual describes in the simplest detail how to manufacture and
            erect this dome. There are no exotic pieces or exotic tools needed.
            All the parts are available at any big box store or in the debris of
            a hurricane. Tools needed to manufacture the dome consists of a jig
            saw, a miter saw, a drill press or some version of them. Many snags,
            pitfalls, and problems have been anticipated, but more are bound to
            arise. Please pass these instances forward to help provide further
            clarity in future revisions.
          </p>

          <p>
            The manual is full of illustrations and simple drawings of struts
            and hubs. The dome is secured together with wooden pegs and there
            are pictures to help.
          </p>

          <p>
            Over 60 pages ensure that many questions are answered. Every attempt
            has been made to keep this manual in a state that does not become
            too inflated with extraneous jargon or crippling solutions that are
            at best superfluous.
          </p>

          <p style={{ marginTop: "1.5rem" }}>
            The manual is available in PDF format for $100. After completing
            your purchase, an automated email with a download link will be sent
            to the email you provide during checkout.
          </p>

          <p>
            The manual can also be printed and bound (as seen in the photo
            above) for an additional $30 bringing a manuals cost to $130. If you
            prefer to purchase a physical copy, the purhcase must be made
            through PayPal -{" "}
            <a
              href="https://paypal.me/ryankylelassiter"
              target="_blank"
              rel="noopener noreferrer"
            >
              paypal.me/ryankylelassiter
            </a>
            . Please include your mailing address in the notes on PayPal.
          </p>

          <p>
            If you have any further questions about the manual, please fill out
            the form below. We will respond as quickly as possible!
          </p>

          <div
            style={{
              width: "100%",
              textAlign: "center",
              marginBottom: "1.5rem",
            }}
          >
            <BuyManualButton />
          </div>

          <ContactForm path={location.pathname} />
        </ContentContainer>
      </Container>
    </>
  );
};

export default Manual;
